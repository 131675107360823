.hero {
    display: flex;
    padding-top: 1rem !important;
}

@media screen and (min-width: 1050px) {
    .hero {
        height: calc(100vh - 100px);
    }
}

.hero-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.hero-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 70px;
}

.hero-content h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 45.7px;
    color: #fff;
    margin-top: -17px;
    letter-spacing: 3px;
}

.hero-content p {
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.explore-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: #67fa8e;
    border-radius: 5px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0 1rem;
    margin-top: 1.5rem;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38%;
}

.hero-image img {
    width: 100%;
    margin-right: 100px;
}

@media screen and (max-width: 1050px) {
    .hero {
        flex-direction: column;
    }

    .hero-content {
        margin: 0 0 3rem;
    }

    .hero-image {
        width: 100%;
    }

    .hero-image img {
        margin-right: auto;
    }

    .hero-scroll-animation {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .hero h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .hero h2 {
        font-size: 34px;
        margin-top: -14px;
        font-weight: 400;
        letter-spacing: 3.3px;
    }

    .hero p {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-image img {
        margin-right: auto;
    }

    .explore-btn {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-scroll-animation {
        display: none;
    }
}

@media screen and (max-width: 490px) {
    .hero h1 {
        font-size: 36px;
        line-height: 36px;
    }

    .hero h2 {
        font-size: 22px;
        margin-top: -3px;
        font-weight: 400;
        letter-spacing: 3.3px;
        line-height: 25px;
    }

    .hero p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 1rem;
    }

    .hero-image img {
        margin-right: auto;
    }

    .explore-btn {
        font-size: 15px;
        line-height: 16px;
    }

    .hero-scroll-animation {
        display: none;
    }
}

/* Scroll Animation */
html body [data-ca3_iconfont="ETmodules"]::before {
    font-family: "ETmodules";
}

[data-ca3_icon]::before {
    font-weight: normal;
    content: attr(data-ca3_icon);
}

.ca3-scroll-down-arrow {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
    background-size: contain;
    background-repeat: no-repeat;
}

.ca3-scroll-down-link {
    cursor: pointer;
    height: 60px;
    width: 80px;
    margin: 0px 0 0 -40px;
    line-height: 60px;
    position: absolute;
    left: 50%;
    bottom: 2rem;
    color: #FFF;
    text-align: center;
    font-size: 70px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

    -webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
    -moz-animation: ca3_fade_move_down 2s ease-in-out infinite;
    animation: ca3_fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
    0% {
        -webkit-transform: translate(0, -20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
}

@-moz-keyframes ca3_fade_move_down {
    0% {
        -moz-transform: translate(0, -20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -moz-transform: translate(0, 20px);
        opacity: 0;
    }
}

@keyframes ca3_fade_move_down {
    0% {
        transform: translate(0, -20px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 20px);
        opacity: 0;
    }
}

/* Scroll animation ends here */