.footer{
    background: var(--color-footer);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.contact-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2rem;
}

.contact-details-child{
    display: flex;
    align-items: center;
    margin: 1rem;
}

.contact-details-child p{
    color: #fff;
    margin-left: 1rem;
    font-size: 14px;

}

.footer-copyright p{
    color: #fff;
    font-size: 14px;
    transform: translateY(45px);
}

@media screen and (max-width:480px) {
    .footer-copyright p{
        font-size: 10px;
    }
}