.socialmedia{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
}

.socialmedia-icon{
    margin: 0 2rem;
}

.socialmedia-icon svg:hover{
    color: #ff4820;
    transition: color 0.3s;
}

@media screen and (max-width:480px) {
    .socialmedia-icon{
        margin: 0 0.5rem;
    }
}